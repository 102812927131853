import { FC, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Hooks
import { useGetTheme } from "../../hooks/useGetTheme";
import { useGetCart } from "../../hooks/useGetCart";

// API
import { useProduct } from "../../context/Product";
import { CartItem } from "../../types/types";
import { shouldButtonDisable } from "../../utilities/products";
import { updateCart } from "../../api/updateCart";
import { useAppContext } from "../../context/App";

interface AddToCartButtonInterface {
    setShow: Function;
    editData: CartItem;
}

export const UpdateCartButton: FC<AddToCartButtonInterface> = ({
    setShow,
    editData
}) => {
    
    const {
        productDetails,
        selectedOptions,
        enteredOptions
    } = useProduct();

    const {
        addError
    } = useAppContext();

    const queryClient = useQueryClient();
    const cart = useGetCart();
    const theme = useGetTheme();

    const [disableButton, setDisableButton] = useState(false);

    const updateCartApi = useMutation({
        mutationFn: updateCart,
        onSuccess: (data) => {
            if (data?.errorHandling?.success) {
                queryClient.setQueryData(['cart'], (oldData: any) => {
                    return {
                        ...oldData,
                        cart: data.cart
                    };
                });

                setShow(false);
            }
            
            addError('updateCartItems', data);
        },
        onError: (err) => {
            addError('updateCartItems', null, "Error updateCartApi failed");
        }
    });

    // map the selected options
    /**
     * 
     * @returns [{
     *  id: "1" // This is the ID of the group
     *  value_string: "1" // This is a comma separated list of ID's for the actual option values
     * }]
     */
     function generateCustomizableOptions() {
        const item = productDetails;
        const allSelectedOptions: { id?: any; value_string?: any; }[] = [];

        if (item?.purchaseOptions && item?.purchaseOptions.length) {
            item?.purchaseOptions.forEach((itemOption) => {

                if (itemOption.type === 'area') {
                    let value = enteredOptions.filter((option: { uid: string; }) => option.uid === itemOption.uid)[0]?.value
                    // Cater for untouched text area on edit.
                    let insertOption = {
                        id: itemOption.id,
                        value_string: value ? value : ""
                    };

                    allSelectedOptions.push(insertOption);
                } else {
                    // Check for selected values
                    console.log("updatecartbutton-", selectedOptions);
                    console.log("itemOption?.uid-", itemOption?.uid);
                    if (
                        selectedOptions[itemOption?.uid] && 
                        selectedOptions[itemOption?.uid]?.value && 
                        selectedOptions[itemOption?.uid].value.length
                    ) {
                        let insertOption = {
                            id: '',
                            value_string: ''
                        };

                        // Option id, e.g 1 = bread
                        insertOption.id = itemOption?.id;

                        // Loop through values inside selected Options
                        selectedOptions[itemOption?.uid].value.forEach((uid: string) => {
                            // Find the ID for this option value
                            let optionArray = itemOption.values.filter((value) => value.uid === uid)[0];

                            if (insertOption.value_string === '') {
                                insertOption.value_string = `${optionArray.id}`;
                                return;
                            }
                            
                            insertOption.value_string += `,${optionArray.id}`;
                        });

                        allSelectedOptions.push(insertOption);
                    } else {
                        // We have to pass 0 to remove the option properly
                        allSelectedOptions.push({
                            id: itemOption?.id,
                            value_string: 0
                        });
                    }
                }
            });

        }

        console.log("allSelectedOptions", allSelectedOptions);

        return allSelectedOptions || [];
    }

    useEffect(() => {
        setDisableButton(shouldButtonDisable(productDetails, selectedOptions, enteredOptions));
    }, [
        productDetails, 
        selectedOptions, 
        enteredOptions
    ]);

    if(!productDetails?.inStock) return null;

    return(
        <button
            className={`btn w-1/2 ${disableButton ? 'opacity-20' : ''}`}
            style={{
                backgroundColor: theme.secondaryColor,
                color: '#FFFFFF'
            }}
            disabled={disableButton}
            onClick={() => {
                console.log(selectedOptions);

                updateCartApi.mutate({
                    cartID: cart?.cart.id ?? '',
                    cartItems: [{
                        cart_item_id: editData?.id,
                        quantity: editData?.quantity,
                        customizable_options: generateCustomizableOptions()
                    }]
                });
            }}
        >
            Update
        </button>
    );
}

export default UpdateCartButton;