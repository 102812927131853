import { useGetTheme } from "../hooks/useGetTheme";

import Logo from "../components/Logo";
import { useEffect } from "react";

function Setup() {
    const theme = useGetTheme();

    useEffect(() => {
        const config = {
            backendUrl: 'https://s21preorderbackend.fidelityportal.co.uk',
            bearerToken: 'blabla',
            timeoutModal: 300000, // 5 Minutes
            pinCode: 1234,
        };

        localStorage.setItem('config', JSON.stringify(config));
    }, []);

    // Endpoint to log in and fetch this
    // Endpoint to refresh
    // Endpoint to change pin
    // Endpoint to change timeout

    return (
        <div 
            className="flex flex-col h-dvh min-h-[1600px]"
        >

            <Logo />

            <div className="text-black text-3xl font-bold">
                CLOUD URL: {process.env.NEXT_PUBLIC_CLOUD_URL}
            </div>

            
        </div>
    )
}

export default Setup;