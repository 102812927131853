import { FC } from "react";
import { useGetTheme } from "../../../hooks/useGetTheme";
import Modal from "../../Modal";

interface PinModalInterface {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PinModal: FC<PinModalInterface> = ({ show, setShow }) => {
    const theme = useGetTheme();

    return (
        <Modal
            show={show}
            setShow={setShow}
            onClose={()=> {
            }}
            width={420}
        >
            <div className="bg-white">
                <p className="font-bold text-4xl mb-5 text-center">Enter Pin</p>
                <p className="text-error mb-5">Incorrect Pin.</p>
                <input type="number" className="border border-[#CBD5E1] rounded-sm mb-5 w-full h-[100px] px-5 text-[60px] text-center font-bold outline-none" />

                <div className="grid grid-cols-3 gap-5">
                    <button className="pinNumber">1</button>
                    <button className="pinNumber">2</button>
                    <button className="pinNumber">3</button>
                    <button className="pinNumber">4</button>
                    <button className="pinNumber">5</button>
                    <button className="pinNumber">6</button>
                    <button className="pinNumber">7</button>
                    <button className="pinNumber">8</button>
                    <button className="pinNumber">9</button>
                    <button className="pinNumber"></button>
                    <button className="pinNumber">0</button>
                    <button 
                        className="pinNumber text-center flex justify-center items-center" 
                        style={{
                            backgroundColor: theme?.secondaryColor ?? ''
                        }}
                    >
                        <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                            <path d="M38.75 2.33333V11.6667H9.1175L17.1725 3.29L14 0L0.5 14L14 28L17.1725 24.71L9.1175 16.3333H43.25V2.33333H38.75Z" fill="white"/>
                        </svg>
                    </button>
                </div>
            </div>
        </Modal>
    )
};

export default PinModal;
