import { ERROR_HANDLING } from '../queries/errorHandling';
import { fetcher } from './fetcher';

// Clean up server response (It was returning data.data.cart.cart etc)
function responseCleanup(data: any) {
    return data.data.setDojoKioskTransaction;
}

interface DojoKioskTransactionApiInterface {
    orderId: string,
    transactionId: string
}

export async function setDojoKioskTransaction(payload : DojoKioskTransactionApiInterface) {
    return await fetcher(
        `
            mutation SetDojoKioskTransaction {
                setDojoKioskTransaction(
                    order_id: "${payload.orderId}",
                    transaction_id: "${payload.transactionId}"
                ) {
                    fullReceipt
                    kioskOrderNumber
                    success
                    ${ERROR_HANDLING}
                }
            }
        `,
        responseCleanup
    );
}