import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import fetchStoreBranding from "../api/fetchStoreBranding";
import { useAppContext } from "../context/App";

function StartScreen() {
    const navigate = useNavigate();

    const {
        addError
    } = useAppContext();

    const { data } = useQuery({
        queryKey: ["branding"],
        queryFn: fetchStoreBranding,
        onSuccess: (data) => {
            addError('Stores', data);

            if(data.errorHandling.success) {
                navigate("start");
            }
        },
        onError: (err) => {
            addError('Stores', null, "Error branding fetch failed");
        },
        staleTime: (1000 * 60) * 60 * 24 // 24 Hours
    });

    if(!data?.items || (!data?.items && !data?.items.length)) {
        return null;
    }

    return (
        <div className="flex flex-col relative">
            <img src={data?.items[0]?.theme?.kioskStartScreenImage} className="w-full" alt="Start Screen" />
        </div>
    )
}

export default StartScreen;