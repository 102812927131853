import { fetcher } from "./fetcher";
import { ERROR_HANDLING } from '../queries/errorHandling';

function cleanup(data: any) {
    return data.data.stratus21Stores;
}

const STORE_INFO = `
    businessInfo
    collection
    delivery
    deliveryMinSpend
    description
    eatIn
    id
    storeViewCode
    name
    specialOffers
    status {
        message
        state
    }
    theme {
        primaryColour
        secondaryColour
        tertiaryColour
        highlightColour
        kioskStartScreenImage
        font
        logo
        image
    }
`;

export default async function fetchStoreBranding() {
    let storeBrandingQuery = 
    `query {
        stratus21Stores(store_type: "kiosk") {
            items {
                ${STORE_INFO}
            }
            ${ERROR_HANDLING}
        }
    }`

    return await fetcher(storeBrandingQuery, cleanup);
}