import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/App";
import { resetGuestCartIdInLocalStorage } from "../utilities/localStorage";

export const useRestartKiosk = () => {
    const {
        setPaymentInformation
    } = useAppContext();

    const navigate = useNavigate();

    function restartKiosk() {
        setPaymentInformation({
            orderType: '',
            orderNumber: '',
            fullReceipt: '',
            tableNumber: ''
        });
        
        resetGuestCartIdInLocalStorage();
        navigate('/start');
    }

    return {
        restartKiosk
    };
};
