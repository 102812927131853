import React, { createContext, useContext, useState, ReactNode } from 'react';

import { EnteredOptions, MenuProduct, SelectedOptions } from '../types/types';

// interface SelectedOptions {
//     value: string[],
//     type: "area" | "multiple" | "radio",
//     quantities: {
//         [key: string]: number;
//     },
//     totals: {
//         [key: string]: number;
//     }
// }

interface ProductContextProps {
    productDetails: MenuProduct;
    selectedOptions: SelectedOptions;
    setSelectedOptions: Function;
    enteredOptions: EnteredOptions[];
    setEnteredOptions: Function;
    selectedCustomizabilityOptions: string[];
    setSelectedCustomizabilityOptions: React.Dispatch<React.SetStateAction<string[]>>;
    selectedTextAreaCustomizabilityOptions: EnteredOptions[];
    setSelectedTextAreaCustomizabilityOptions: Function;
}

const ProductContext = createContext<ProductContextProps | undefined>(undefined);

export const ProductWrapper: React.FC<{ children: ReactNode, productDetails: MenuProduct }> = ({ 
    children, 
    productDetails 
}) => {
    const [selectedOptions, setSelectedOptions] = useState({}); // Checkboxes and Radio Values
    const [enteredOptions, setEnteredOptions] = useState([]); // Textareas
    const [selectedCustomizabilityOptions, setSelectedCustomizabilityOptions] = useState(['']); // Edit data for checkboxes and radios
    const [selectedTextAreaCustomizabilityOptions, setSelectedTextAreaCustomizabilityOptions] = useState([]); // Edit data for text areas

    return (
        <ProductContext.Provider
            value={{
                productDetails,
                selectedOptions, 
                setSelectedOptions,
                enteredOptions, 
                setEnteredOptions,
                selectedCustomizabilityOptions,
                setSelectedCustomizabilityOptions,
                selectedTextAreaCustomizabilityOptions,
                setSelectedTextAreaCustomizabilityOptions
            }}
        >
            {children}
        </ProductContext.Provider>
    );
};

export const useProduct = (): ProductContextProps => {
    const context = useContext(ProductContext);
    if (context === undefined) {
        throw new Error('useMenu must be used within a MenuProvider');
    }
    return context;
};