import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetCart } from '../../hooks/useGetCart';
import { useGetTheme } from '../../hooks/useGetTheme';
import { useDojo } from '../../hooks/useDojo';

export const MenuButtons: FC = () => {
    const theme = useGetTheme();
    const cart = useGetCart();
    const navigate = useNavigate();

    const {
        placeOrder
    } = useDojo();

    if(!cart?.cart?.prices?.grand_total.value) return null;

    return (
        <div 
            className="px-11 py-8 bg-limeGreen absolute bottom-0 shadow-lg w-full"
        >
            <p className="text text-4xl mb-5 text-right">Total: <strong className="text-5xl">{cart?.cart?.prices?.grand_total.currency === 'GBP' ? '£' : '£'}{cart?.cart?.prices?.grand_total.value.toFixed(2)}</strong></p>

            <div className="flex">
                <button
                    className="btn btn-small-spacing btn-body mr-8 w-1/2"
                    onClick={() => {
                        navigate("/cart");
                    }}
                >
                    View order
                </button>

                <button
                    className="btn btn-small-spacing w-1/2"
                    style={{
                        backgroundColor: theme.secondaryColor,
                        color: '#FFFFFF'
                    }}
                    onClick={() => {
                        placeOrder('/payment');
                    }}
                >
                    Complete order
                </button>
            </div>
        </div>
    );
};

export default MenuButtons;

