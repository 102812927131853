import { ERROR_HANDLING } from '../queries/errorHandling';
import { fetcher } from './fetcher';

// Clean up server response (It was returning data.data.cart.cart etc)
function responseCleanup(data: any) {
    return data.data.createEmptyCart;
}

export async function setCartId() {
    return await fetcher(
        `
            mutation CreateCartSession {
                createEmptyCart(input: {
                    store_type: "kiosk"
                }) {
                    id
                    ${ERROR_HANDLING}
                }
            }
        `,
        responseCleanup
    );
}