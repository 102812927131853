import { ERROR_HANDLING } from '../queries/errorHandling';
import { fetcher } from './fetcher';

// Clean up server response (It was returning data.data.cart.cart etc)
function responseCleanup(data: any) {
    return data.data.placeOrder;
}

interface SetPlaceOrderInterface {
    cartID : string
}

export async function setPlaceOrder(payload: SetPlaceOrderInterface) {
    return await fetcher(
        `
        mutation {
            placeOrder(
                input: {
                    cart_id: "${payload.cartID}"
                }
            ) {
                order {
                    order_id
                }
                placeOrderMessage
                ${ERROR_HANDLING}
            }
        }
        `,
        responseCleanup
    );
}