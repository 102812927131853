import { FC } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { removeFromCart } from '../../api/removeFromCart';
import { useGetCart } from "../../hooks/useGetCart";

import Modal from "../Modal";
import { useAppContext } from "../../context/App";

interface RemoveItemModalInterface {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    productId: number;
}

export const RemoveItemModal: FC<RemoveItemModalInterface> = ({ show, setShow, productId }) => {
    const cart = useGetCart();
    const queryClient = useQueryClient();

    const {
        addError
    } = useAppContext();

    const removeFromCartApi = useMutation({
        mutationFn: removeFromCart,
        onSuccess: (data) => {
            if (data?.errorHandling?.success) {
                queryClient.setQueryData(['cart'], (oldData: any) => {
                    return {
                        ...oldData,
                        cart: data.cart
                    };
                });
                
                setShow(false);
            }

            addError('removeItemFromCart', data);
        },
        onError: (err) => {
            addError('removeItemFromCart', null, "Error removeItemFromCart failed");
        }
    });

    return (
        <Modal
            show={show}
            setShow={setShow}
        >
            <p className='font-bold mb-8 text-center text-[29px]'>Are you sure you want to remove this from your order?</p>
            <div className='flex'>
                <button 
                    className='btn btn-small-spacing btn-body mr-4 w-1/2' 
                    onClick={() => {
                        setShow(false);
                    }}
                >
                    No
                </button>

                <button 
                    className='btn btn-small-spacing btn-danger w-1/2'
                    onClick={() => {
                        removeFromCartApi.mutate({
                            cartId: cart?.cart.id ?? '',
                            cartItemId: productId
                        });
                    }}
                >
                    Yes
                </button>
            </div>
        </Modal>
    )
};

export default RemoveItemModal;