import { useState } from 'react';
import ErrorHandlingModal from './components/ErrorHandlingModal';
import Loader from './components/Loader';
import PinModal from './components/Modals/Admin/pinModal';
import { AppProvider } from './context/App';
import Providers from './providers';

export default function Layout({ children }: { children: React.ReactNode }) {
    const [show, setShow] = useState(false);

    return (
        <Providers>
            {/* Screen is 900px wide by 1300px in height */}
            <div className='w-[900px] mx-auto relative h-[1600px]'>
                <AppProvider>
                    <>
                        <button onClick={()=>{setShow(true)}} className='absolute left-0 top-0 w-[45px] h-[45px] z-10'>admin</button>

                        <PinModal show={show} setShow={setShow} />

                        {children}
                        <ErrorHandlingModal />
                    </>
                </AppProvider>
            </div>

            <Loader />
        </Providers>
    );
}
