// React
import { useCallback, FC, useEffect, useState } from "react";
import { useProduct } from "../../context/Product";
import { PurchaseOptions, PurchaseOptionValue, SelectedOption } from "../../types/types";

interface ProductRadioInterface {
    radioData: PurchaseOptionValue
    selectedOption: SelectedOption
    productOption: PurchaseOptions
}

export const ProductRadio: FC<ProductRadioInterface> = ({
    radioData,
    selectedOption,
    productOption
}) => {

    const [populateFromEdit, setPopulateFromEdit] = useState(false);

    const {
        setSelectedOptions,
        selectedCustomizabilityOptions
    } = useProduct();

    const isRadioSelected = () => {
        if(selectedCustomizabilityOptions.includes(radioData.uid) && !populateFromEdit) return true;
        if(selectedOption?.value && selectedOption?.value.length && selectedOption?.value.includes(radioData.uid)) return true;
    };
 
    // Push value into selected options
    const updateRadio = useCallback(() => {
        // Fetch data in selected options
        const currentData = selectedOption;

        // Modify the selectedValues
        if (isRadioSelected() === true) {
            currentData.value = [];
        } else {
            currentData.value = [radioData.uid];
        }

        setSelectedOptions((prevState: any) => ({
            ...prevState,
            [productOption.uid]: currentData
        }));

    }, [isRadioSelected, radioData.uid, selectedOption, setSelectedOptions]);

    // Should only run once
    useEffect(() => {
        if (!populateFromEdit && selectedCustomizabilityOptions.includes(radioData.uid)) {
            const currentData = selectedOption;
            currentData.value = [radioData.uid];

            setPopulateFromEdit(true);
            setSelectedOptions((prevState: any) => ({
                ...prevState,
                [productOption.uid]: currentData
            }));
        }
    }, [selectedCustomizabilityOptions, radioData, updateRadio, productOption.uid, populateFromEdit, selectedOption, setSelectedOptions]);

    return (
        <button
            data-test="radioInput"
            onClick={() => { updateRadio() }}
            className="w-full bg-menu p-5 flex justify-between items-center font-bold text-xl rounded-lg mb-1"
        >
            {radioData.label}

            <div
                className="flex items-center"
                style={{
                    color: 'yellow',
                    minHeight: '39px'
                }}>

                {radioData?.pricePerUnit && (radioData?.pricePerUnit < 0 || radioData?.pricePerUnit > 0) ? (
                    <p className="text-black m-0 mx-3 h-10">{radioData?.pricePerUnitFormatted}</p>
                ) : null}

                {/* If radio is selected */}
                {isRadioSelected() ? (
                    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 0.75C9.15 0.75 0.75 9.15 0.75 19.5C0.75 29.85 9.15 38.25 19.5 38.25C29.85 38.25 38.25 29.85 38.25 19.5C38.25 9.15 29.85 0.75 19.5 0.75ZM15.75 28.875L6.375 19.5L9.01875 16.8562L15.75 23.5687L29.9812 9.3375L32.625 12L15.75 28.875Z" fill="#1F2245"/>
                    </svg>                    
                ) : null}
            </div>
        </button>
    )
}

export default ProductRadio;