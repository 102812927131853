import { FC, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Hooks
import { useGetTheme } from "../../hooks/useGetTheme";
import { useGetCart } from "../../hooks/useGetCart";

// Types

// API
import { addToCart } from "../../api/addToCart";
import { useProduct } from "../../context/Product";
import { shouldButtonDisable } from "../../utilities/products";
import { useAppContext } from "../../context/App";

interface AddToCartButtonInterface {
    setShow: Function;
}

export const AddToCartButton: FC<AddToCartButtonInterface> = ({
    setShow
}) => {
    
    const {
        productDetails,
        selectedOptions,
        enteredOptions
    } = useProduct();

    const {
        addError
    } = useAppContext();

    const queryClient = useQueryClient();
    const cart = useGetCart();
    const theme = useGetTheme();

    const [disableButton, setDisableButton] = useState(false);

    const addToCartApi = useMutation({
        mutationFn: addToCart,
        onSuccess: (data) => {
            if (data?.errorHandling?.success) {
                queryClient.setQueryData(['cart'], (oldData: any) => {
                    return {
                        ...oldData,
                        cart: data.cart
                    };
                });

                setShow(false);
            }
            
            addError('addProductsToCart', data);
        },
        onError: (err) => {
            addError('addProductsToCart', null, "Error AddToCartButton failed");
        }
    });

    // Compile all options into one selected options array
    const generateValues = () => {
        let values: string[] = [];

        // Loop through product Options to combine all selected options
        if (productDetails?.purchaseOptions && productDetails?.purchaseOptions.length) {
            productDetails.purchaseOptions.forEach((option) => {
                // Skip over Area
                if (option.type !== 'area' && selectedOptions[option.uid] !== undefined && selectedOptions[option.uid].value.length) {
                    // Loop through all selected values and push into values array
                    selectedOptions[option.uid].value.forEach((value: any) => {
                        values.push(value);
                    });
                }
            });
        }

        return values;
    };

    useEffect(() => {
        setDisableButton(shouldButtonDisable(productDetails, selectedOptions, enteredOptions));
    }, [
        productDetails, 
        selectedOptions, 
        enteredOptions
    ]);

    if(!productDetails?.inStock) return null;

    return(
        <button
            className={`btn w-1/2 ${disableButton ? 'opacity-20' : ''}`}
            style={{
                backgroundColor: theme.secondaryColor,
                color: '#FFFFFF'
            }}
            disabled={disableButton}
            onClick={() => {
                console.log(selectedOptions);
                addToCartApi.mutate({
                    cartID: cart?.cart.id ?? '',
                    cartItems: [{
                        quantity: 1,
                        sku: productDetails?.sku,
                        selected_options: generateValues(), // Checkboxes and radios
                        entered_options: enteredOptions // [{ uid: '', value: ''}]
                    }]
                });
            }}
        >
            Add to order
        </button>
    );
}

export default AddToCartButton;