import { FC } from "react";
import { useGetTheme } from "../../hooks/useGetTheme";

import Modal from "../Modal";
import useLight from "../../hooks/useLight";

interface LowPrinterPaperModalInterface {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LowPrinterPaperModal: FC<LowPrinterPaperModalInterface> = ({ show, setShow }) => {
    const theme = useGetTheme();
    
    const { 
        handleStartBlinkingLamp,
        handleStopBlinkingLamp
    } = useLight();

    if(show) {
        handleStartBlinkingLamp("Led-1");
    }
    
    return (
        <Modal
            show={show}
            setShow={setShow}
            onClose={()=> {
                handleStopBlinkingLamp("Led-1");
            }}
        >
            <div 
                className="rounded-full p-5 mx-auto mb-8"
                style={{
                    backgroundColor: theme.primaryColor
                }}
            >
                <svg width="37" height="32" viewBox="0 0 37 32" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[50px] h-[50px]'>
                    <path d="M31.3701 32C30.8556 32 30.4243 31.8296 30.0763 31.4889C29.7282 31.1481 29.5542 30.7259 29.5542 30.2222C29.5542 29.7185 29.7282 29.2963 30.0763 28.9556C30.4243 28.6148 30.8556 28.4444 31.3701 28.4444C31.8846 28.4444 32.3159 28.6148 32.6639 28.9556C33.012 29.2963 33.186 29.7185 33.186 30.2222C33.186 30.7259 33.012 31.1481 32.6639 31.4889C32.3159 31.8296 31.8846 32 31.3701 32ZM31.3701 24.8889C30.8556 24.8889 30.4243 24.7185 30.0763 24.3778C29.7282 24.037 29.5542 23.6148 29.5542 23.1111V17.7778C29.5542 17.2741 29.7282 16.8519 30.0763 16.5111C30.4243 16.1704 30.8556 16 31.3701 16C31.8846 16 32.3159 16.1704 32.6639 16.5111C33.012 16.8519 33.186 17.2741 33.186 17.7778V23.1111C33.186 23.6148 33.012 24.037 32.6639 24.3778C32.3159 24.7185 31.8846 24.8889 31.3701 24.8889ZM9.57944 32C9.06494 32 8.63367 31.8296 8.28562 31.4889C7.93758 31.1481 7.76356 30.7259 7.76356 30.2222V24.8889H2.31589C1.80139 24.8889 1.37011 24.7185 1.02207 24.3778C0.674023 24.037 0.5 23.6148 0.5 23.1111V14.2222C0.5 12.7111 1.02963 11.4444 2.0889 10.4222C3.14817 9.4 4.43443 8.88889 5.94767 8.88889H31.3701C32.5807 8.88889 33.6475 9.22222 34.5706 9.88889C35.4937 10.5556 36.1368 11.4074 36.5 12.4444H29.5542C28.5555 12.4444 27.7005 12.7926 26.9893 13.4889C26.2781 14.1852 25.9224 15.0222 25.9224 16V21.3333H11.3953V28.4444H25.9224V32H9.57944ZM7.76356 7.11111V3.55556C7.76356 2.57778 8.11917 1.74074 8.83039 1.04444C9.54161 0.348148 10.3966 0 11.3953 0H25.9224C26.9212 0 27.7762 0.348148 28.4874 1.04444C29.1986 1.74074 29.5542 2.57778 29.5542 3.55556V7.11111H7.76356Z" fill="white"/>
                </svg>
            </div>

            <p className='font-bold mb-8 text-center text-[29px]'>Unable to print receipt</p>
            <p className="mb-8 text-center">Please make a note of your <strong>order number</strong>, if you encounter any problems please speak to a member of staff.</p>

            <div className='flex'>
                <button 
                    className='btn btn-small-spacing btn-danger w-full'
                    onClick={() => {
                        handleStopBlinkingLamp("Led-1");
                        setShow(false);
                    }}
                >
                    Confirm
                </button>
            </div>
        </Modal>
    )
};

export default LowPrinterPaperModal;