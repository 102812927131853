import { MenuProduct } from './../types/types';

interface SelectedOptions {
  [key: string]: {
    value: string[];
  };
}

interface EnteredOption {
  uid: string;
  value: string;
}

/**
 * shouldButtonDisable - Decide whether or not the update/add to cart buttons should be disabled
 * @param item - The MenuProduct item
 * @param selectedOptions - Checkboxes and Radios
 * @param enteredOptions - Text Areas
 * @returns true if the button should be disabled, false otherwise
 */
export const shouldButtonDisable = (
  item: MenuProduct, 
  selectedOptions: SelectedOptions, 
  enteredOptions: EnteredOption[]
): boolean => {
    if (!item.purchaseOptions || !item.purchaseOptions.length) {
        return false; // If no purchase options exist, don't disable the button
    }

    let disableAddToCart = false;

    for (const groupOption of item.purchaseOptions) {
        const { type, required, uid, values } = groupOption;

        if (required && type === 'radio') {
                const radioSelected = values.some(option => 
                    selectedOptions[uid]?.value.includes(option.uid)
                );

                if (!radioSelected) {
                disableAddToCart = true;
                break;
            }
        }

        if (required && type === 'multiple') {
            const optionSelected = selectedOptions[uid]?.value.length > 0;

            if (!optionSelected) {
                disableAddToCart = true;
                break;
            }
        }

        if (required && type === 'area') {
            const areaValue = enteredOptions.find(option => option.uid === uid);

            if (!areaValue || areaValue.value.trim() === '') {
                disableAddToCart = true;
                break;
            }
        }
    }

    return disableAddToCart;
};
