import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { setOrderType } from "../api/setOrderType";

import { useGetTheme } from "../hooks/useGetTheme";
import { useGetCart } from "../hooks/useGetCart";

import Hero from "../components/Hero";
import SelectTableNumber from "../components/OrderType/SelectTableNumber";
import TimeoutModal from "../components/Modals/TimeoutModal";
import { useAppContext } from "../context/App";

function OrderType() {
    const navigate = useNavigate();
    const theme = useGetTheme();
    const cart = useGetCart();

    const [selectedOrderType, setSelectedOrderType] = useState('');
    const [showTableNumbers, setShowTableNumbers] = useState(false);
    const [selectedTableNumber, setSelectedTableNumber] = useState({
        value: '',
        label: ''
    });

    const {
        addError
    } = useAppContext();

    const setOrderTypeAPI = useMutation({
        mutationFn: setOrderType,
        onSuccess: (data) => {
            addError('SetOrderType', data);

            setShowTableNumbers(false);

            if (data?.errorHandling?.success) {
                window.scrollTo(0, 0);
                navigate('/menu');
            }
        },
        onError: (err) => {
            addError('SetOrderType', null, "Error set order type failed");
        }
    });

    return (
        <div
            className="flex flex-col bg-white h-[1600px]"
        >
            <Hero />

            <div className="mt-[166px]">

                {showTableNumbers ? (
                    <SelectTableNumber
                        selectedOrderType={selectedOrderType}
                        setSelectedTableNumber={setSelectedTableNumber}
                    />
                ) : (
                    <div className="flex flex-col mx-auto text-center">
                        <p 
                            className="text-[48px] font-bold" 
                            style={{
                                color: theme.primaryColor
                            }}
                        >
                            Select order type:
                        </p>

                        <div className="flex justify-center mt-[85px] text-5xl font-bold mb-20">
                            <button
                                className="mr-20"
                                onClick={() => {
                                    setSelectedOrderType('inside');
                                    setShowTableNumbers(true);
                                }}
                            >
                                <span
                                    className={`w-[250px] h-[250px] flex items-center justify-center mb-6 rounded-lg shadow-lg border-2 ${selectedOrderType === 'inside' ? 'border-slate-950' : ''}`}
                                    style={{
                                        backgroundColor: theme.secondaryColor
                                    }}
                                >
                                    <svg 
                                        width="108" 
                                        height="106" viewBox="0 0 108 106" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        className="w-20"
                                        style={{
                                            color: theme.primaryColor
                                        }}
                                    >
                                        <path d="M4.76169 103.307C3.67603 102.221 3.13321 100.839 3.13321 99.1616C3.13321 97.4838 3.67603 96.102 4.76169 95.0164L61.3145 38.4636C59.5379 34.3184 59.2912 29.6303 60.5742 24.3994C61.8573 19.1685 64.6701 14.4805 69.0127 10.3352C74.2436 5.10436 80.0667 2.04478 86.4819 1.15652C92.8972 0.268255 98.1281 1.84739 102.175 5.89392C106.221 9.94046 107.8 15.1713 106.912 21.5866C106.024 28.0018 102.964 33.8249 97.7333 39.0558C93.588 43.3984 88.9 46.2112 83.6691 47.4943C78.4382 48.7773 73.7502 48.5306 69.6049 46.754L62.2027 54.1562L103.063 95.0164C104.149 96.102 104.691 97.4838 104.691 99.1616C104.691 100.839 104.149 102.221 103.063 103.307C101.879 104.491 100.497 105.083 98.9176 105.083C97.3385 105.083 95.9567 104.491 94.7724 103.307L53.9123 62.7428L13.0521 103.307C11.8678 104.491 10.4861 105.083 8.90692 105.083C7.32778 105.083 5.94604 104.491 4.76169 103.307ZM26.3761 56.821L8.61083 39.0558C4.16951 34.6144 1.57776 29.4329 0.835564 23.5111C0.0973183 17.5894 1.20863 12.0131 4.16951 6.78219C5.15647 5.10436 6.63691 4.16675 8.61083 3.96935C10.5847 3.77196 12.3119 4.46283 13.7924 6.04197L45.3257 37.8714L26.3761 56.821Z" fill="currentColor"/>
                                    </svg>
                                </span>

                                Eat in
                            </button>

                            <button
                                onClick={() => {
                                    setSelectedOrderType('collection');
                                }}
                            >
                                <span
                                    className={`w-[250px] h-[250px] flex items-center justify-center mb-6 rounded-lg shadow-lg border-2 ${selectedOrderType === 'collection' ? 'border-slate-950' : ''}`}
                                    style={{
                                        backgroundColor: theme.secondaryColor
                                    }}
                                >
                                    <svg 
                                        width="84" height="106" viewBox="0 0 84 106" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        className="w-20"
                                        style={{
                                            color: theme.primaryColor
                                        }}
                                    >
                                        <path d="M73.25 21.75H62.8334C62.8334 10.2396 53.5105 0.916687 42 0.916687C30.4896 0.916687 21.1667 10.2396 21.1667 21.75H10.75C5.02087 21.75 0.333374 26.4375 0.333374 32.1667V94.6667C0.333374 100.396 5.02087 105.083 10.75 105.083H73.25C78.9792 105.083 83.6667 100.396 83.6667 94.6667V32.1667C83.6667 26.4375 78.9792 21.75 73.25 21.75ZM31.5834 42.5834C31.5834 45.4479 29.2396 47.7917 26.375 47.7917C23.5105 47.7917 21.1667 45.4479 21.1667 42.5834V32.1667H31.5834V42.5834ZM42 11.3334C47.7292 11.3334 52.4167 16.0209 52.4167 21.75H31.5834C31.5834 16.0209 36.2709 11.3334 42 11.3334ZM62.8334 42.5834C62.8334 45.4479 60.4896 47.7917 57.625 47.7917C54.7605 47.7917 52.4167 45.4479 52.4167 42.5834V32.1667H62.8334V42.5834Z" fill="currentColor"/>
                                    </svg>

                                </span>

                                Take out
                            </button>
                        </div>
                    </div>
                )}

                {selectedOrderType === 'collection' || (selectedOrderType === "inside" && selectedTableNumber.value !== '') ? (
                    <div className="flex justify-center">
                        <button
                            className="btn btn-primary btn-text-large mx-auto"
                            onClick={()=> {
                                setOrderTypeAPI.mutate({
                                    cartID: cart?.cart?.id ?? '',
                                    type: selectedOrderType,
                                    timeSlot: "ASAP",
                                    tableNumber: selectedTableNumber?.value,
                                    storeId: 2 // TODO
                                });
                            }}
                            style={{
                                color: theme.primaryColor,
                                backgroundColor: theme.secondaryColor
                            }}
                        >
                            Confirm
                        </button>
                    </div>
                ) : null}
            </div>

            <TimeoutModal />
        </div>
    )
}

export default OrderType;