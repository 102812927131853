import { useQuery } from '@tanstack/react-query';

import fetchCategoriesProducts from '../api/fetchCategoriesProducts';

export const useGetCategories = () => {
    const { data } = useQuery({
        queryKey: ['menu'],
        queryFn: fetchCategoriesProducts,
        staleTime: (1000 * 60) * 60 * 1,
    });

    return data ?? [];
}