import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

import { MenuCategory } from '../types/types';

import { useGetCategories } from '../hooks/useGetCategories';

interface MenuContextProps {
    categories: MenuCategory[];
    handleSearchQueryChange: Function;
    handleCategoryChange: Function;
    searchQuery: string;
    searchTags: string[]
    selectedCategory: string;
    filteredCategories: MenuCategory[];
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined);

export const MenuProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const categories = useGetCategories();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [filteredCategories, setFilteredCategories] = useState<MenuCategory[]>([]);

    console.log(categories);

    const filterCategories = (search: string, categoryId: string) => {
        if (!categories?.items) return;
        let filtered = categories?.items;

        if (categoryId) {
            filtered = filtered.filter((filteredCategory: MenuCategory) => filteredCategory.id === categoryId);
        }

        if (search) {
            filtered = filtered.map((category: MenuCategory) => ({
                ...category,
                products: category.products.filter((product) =>
                    product.name.toLowerCase().includes(search.toLowerCase())
                ),
            })).filter((category: MenuCategory) => category.products.length > 0);
        }

        setFilteredCategories(filtered);
    };

    const handleSearchQueryChange = (query: string) => {
        setSearchQuery(query);
        filterCategories(query, selectedCategory);
    };

    const handleCategoryChange = (categoryId: string) => {
        setSelectedCategory(categoryId);
        filterCategories(searchQuery, categoryId);
    };

    useEffect(() => {
        if(categories.items) {
            handleSearchQueryChange('');
        }
    }, [categories, handleSearchQueryChange]);

    return (
        <MenuContext.Provider
            value={{
                categories: categories?.items,
                searchTags: categories?.searchTerms ?? [],
                filteredCategories: filteredCategories && filteredCategories.length ? filteredCategories : categories ?? [],
                searchQuery,
                selectedCategory,
                handleSearchQueryChange,
                handleCategoryChange
            }}
        >
            {children}
        </MenuContext.Provider>
    );
};

export const useMenu = (): MenuContextProps => {
    const context = useContext(MenuContext);
    if (context === undefined) {
        throw new Error('useMenu must be used within a MenuProvider');
    }
    return context;
};