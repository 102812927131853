import { cartInfo } from '../queries/cart';
import { ERROR_HANDLING } from '../queries/errorHandling';
import { fetcher } from './fetcher';

// Clean up server response (It was returning data.data.cart.cart etc)
function responseCleanup(data: any) {
    return data.data.updateCartItems;
}

interface UpdateCartApiInterface {
    cartID: string,
    cartItems: Array<{
        cart_item_id: string;
        quantity: string;
        customizable_options: any
    }>;
}

export async function updateCart(payload : UpdateCartApiInterface) {
    const cartItemsString = JSON.stringify(payload.cartItems)
    .replace(/"([^"]+)":/g, '$1:');
    
    return await fetcher(
        `
            mutation UpdateItemInCart {
                updateCartItems(
                    input: {
                        cart_id: "${payload.cartID}",
                        cart_items: ${cartItemsString}
                    }
                ) {
                    ${cartInfo}
                    ${ERROR_HANDLING}
                }
            }
        `,
        responseCleanup
    );
}