import { FC } from 'react';
import { useAppContext } from '../context/App';
import Modal from './Modal';

export const ErrorHandlingModal: FC = () => {
    const {
        globalErrors,
        clearErrors
    } = useAppContext();

    console.log(globalErrors, 'globalErrors');
    if(!globalErrors.length) return null;
    return (
        <Modal
            show={true}
            setShow={() => {}}
            onClose={()=> {
                clearErrors();
            }}
        >
            <div 
                className="rounded-full p-5 mx-auto mb-8 btn-danger"
            >   
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    height="24px" 
                    viewBox="0 -960 960 960" 
                    width="24px" 
                    fill="#FFFFFF"
                    className='w-[50px] h-[50px]'
                >
                    <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                </svg>
            </div>

            <p className='font-bold mb-8 text-center text-[29px]'>Help is coming, hang tight!</p>

            <div className='flex flex-col'>
                {globalErrors.map((error: any) => {
                    return (
                        <div className={`bg-danger mb-5 p-4 text-sm text-left rounded-md text-white`}>
                            {error.id}. {error.message}
                        </div>
                    );
                })}

                <button 
                    className='btn btn-small-spacing btn-danger w-full'
                    onClick={() => {
                        clearErrors();
                    }}
                >
                    Cancel Help
                </button>
            </div>
        </Modal>
    );
};

export default ErrorHandlingModal;

