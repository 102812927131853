import { FC, useState } from 'react';

import { useGetCategories } from '../../hooks/useGetCategories';

import { CartItem, MenuCategory, MenuProduct } from '../../types/types';

import ProductModal from '../ProductModal';
import RemoveItemButton from './RemoveItemButton';

interface CartItemInterface {
    item: CartItem,
}

export const Item: FC<CartItemInterface> = ({ item }) => {
    const categories = useGetCategories();
    const [showProductModal, setShowProductModal] = useState(false);

    const products = categories?.items && categories?.items.length ? categories?.items.flatMap((category: MenuCategory) => category.products) : [];
    const foundProduct = products.filter((product: MenuProduct) => product.id === item.product.id)[0];
    
    return (
        <div className="flex mb-4 w-full">
            <button 
                className="bg-white rounded-l-lg p-8 flex w-full"
                onClick={() => {
                    setShowProductModal(true);
                }}
            >
                {item.product.image.url ? (
                    <div className="min-w-[130px] h-[130px] overflow-hidden rounded-lg mr-7 relative">
                        <img 
                            src={item.product.image.url} 
                            alt={item.product.name}
                            className="absolute max-w-fit min-w-[130px] h-[130px]"
                        />
                    </div>
                ) : null}

                <div className="flex flex-col pr-11">
                    <h3 className="font-bold mb-2 text-2xl leading-none text-left">{item.quantity}x {item.product.name}</h3>
                    <p 
                        className="mb-2 text-base leading-normal text-left"
                        dangerouslySetInnerHTML={{
                            __html: item.product.description.html
                        }}
                    >
                    </p>

                    {/* <p className="text-base leading-normal text-left"><strong>Optional Extras:</strong><br/>
                        x1 Bacon (+£1.00)<br/>
                        x2 Sausage (+£2.00)
                    </p> */}
                    
                    {item?.customizable_options && item?.customizable_options.length ? (
                        <>
                            {item.customizable_options.map(option => {

                                let optionValues: any[] = [];

                                return (
                                    <div 
                                        key={option.customizable_option_uid} className='text-left'
                                    >
                                        <strong className="text-base leading-normal text-left font-bold mr-auto">{option.label}</strong>

                                        {/* Loop through values, there may be multiple */}
                                        {option.values.map(value => {

                                            // Work out quantity based on whether there are duplicate values or not
                                            const quantity = option.values.filter(item => item.label === value.label).length;

                                            let output = value.value;

                                            if (value.label) {
                                                // Don't output duplicates
                                                if (!optionValues.includes(value.label)) {
                                                    optionValues.push(value.label);
                                                } else {
                                                    return null;
                                                }

                                                output = quantity > 1 ? `${quantity}x ${value.label}` : value.label;
                                            }

                                            return (
                                                <div
                                                    key={value.customizable_option_value_uid}
                                                    className="text-xs text-left"
                                                >
                                                    {output}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </>
                    ) : null}
                </div>

                <div className="ml-auto">
                    <p className="font-bold text-4xl">£{Number(item.prices.row_total_including_tax.value).toFixed(2)}</p>
                </div>
            </button>

            <RemoveItemButton productId={Number(item.id)}/>

            {foundProduct && foundProduct?.name ? (
                <ProductModal
                    productDetails={foundProduct}
                    editData={item}
                    show={showProductModal} 
                    setShow={setShowProductModal} 
                />
            ) : null}
        </div>
    );
}

export default Item;

