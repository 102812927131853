import { useQuery } from "@tanstack/react-query";
import fetchCart from "../api/fetchCart";
import { CartInterface } from "../types/types";
import { useAppContext } from "../context/App";

export const useGetCart = () => {
    const {
        addError
    } = useAppContext();

    const { data: cart } = useQuery<CartInterface>({
        staleTime: 1000 * 60,
        queryKey: ["cart"],
        queryFn: fetchCart,
        onSuccess: (data) => {
            addError('cart', data);
        },
        onError: (err) => {
            addError('cart', null, "fetchCart api failed");
        }
    });

    return cart
};
