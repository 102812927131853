import { useQuery } from "@tanstack/react-query";
import fetchStoreBranding from "../api/fetchStoreBranding";

interface ThemeObject {
    businessInfo: string
    collection: string
    delivery: string
    deliveryMinSpend: string
    description: string
    eatIn: string
    id: string
    storeViewCode: string
    name: string
    specialOffers: string
    status: {
        message: string
        state: string
    }
    theme: {
        primaryColour: string
        secondaryColour: string
        tertiaryColour: string
        highlightColour: string
        font: string
        logo: string
        image: string
        kioskStartScreenImage: string
    }
}

interface ThemeInterface {
    items: ThemeObject[]
}

export const useGetTheme = () => {
    const { data } = useQuery<ThemeInterface>({
        queryFn: fetchStoreBranding,
        queryKey: ["branding"],
        staleTime: (1000 * 60) * 60 * 24 // 24 Hours
    });

    const theme = data?.items && data?.items[0]?.theme;

    const logo = theme?.logo ?? '';
    const primaryColor = theme?.primaryColour ?? '#000000';
    const secondaryColor = theme?.secondaryColour ?? '#FFFFFF';
    const kioskStartScreenImage = theme?.kioskStartScreenImage ?? '';

    return {
        logo: logo,
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        kioskStartScreenImage: kioskStartScreenImage
    }
};
